import audioPlayer, { formatTime } from "@exp/components/audio-player/audio-player.mjs";
const speedValues = [{
  value: 0.5,
  label: "0.5x"
}, {
  value: 1,
  label: "1.0x"
}, {
  value: 1.2,
  label: "1.2x"
}, {
  value: 1.5,
  label: "1.5x"
}, {
  value: 1.8,
  label: "1.8x"
}, {
  value: 2,
  label: "2.0x"
}];
const eventListeners = new Map();
export default async function init(scope) {
  const player = scope.querySelector(".speech");
  const initSpeechButton = scope.querySelector(".init-speech");
  if (!player || !initSpeechButton) return;
  const audio = player.querySelector("audio");
  const res = await fetch(audio.src, {
    method: "HEAD"
  }).catch(() => null);
  if (!res || !res.ok) return;
  initSpeechButton.classList.remove("hidden");
  initSpeechButton.addEventListener("click", () => handleInitButton(player, audio));
}
function handleInitButton(player, audio) {
  if (player.classList.contains("speech--hidden")) {
    resetPlayer(player);
    player.classList.remove("speech--hidden");
    audio.addEventListener("loadedmetadata", () => {
      const trackingData = JSON.parse(player.dataset.tracking || "{}");
      trackingData.duration = audio.duration;
      player.dataset.tracking = JSON.stringify(trackingData);
      updateAudioData(player, audio);
      audioPlayer(player, "speech");
      const elements = getElements(player);
      removeAllEvents();
      addEvent(elements.popup, "click", () => handlePopup(player, elements.popupText));
      addEvent(elements.selectSpeed?.querySelector("select"), "change", e => handleSelectSpeed(e, audio));
      addEvent(elements.customSelectSpeedButton, "click", () => handleCustomSpeed(elements.customSelectSpeedValues, elements.customSelectSpeedButton, audio));
      addEvent(elements.closeButton, "click", () => handleReset(player, audio, elements));
      animateHeadline(elements.headline);
      if (!window.CHANNEL_DESKTOP) {
        setupMinimize(player, elements);
      }
      elements.playButton.click();
    }, {
      once: true
    });
    audio.load();
  }
}
function resetPlayer(currentPlayer) {
  const players = [...document.querySelectorAll(".speech:not(.speech--hidden)")];
  players.forEach(player => {
    if (player !== currentPlayer) {
      const elements = getElements(player);
      handleReset(player, elements.audio, elements);
    }
  });
}
function handleReset(player, audio, elements) {
  player.classList.add("speech--hidden");
  player.classList.remove("playing", "popup", "minimized");
  elements.backdrop.classList.remove("active");
  elements.popupText.classList.remove("visible");
  removeAllEvents();
  audio.pause();
  audio.currentTime = 0;
  audio.volume = 0.5;
  audio.playbackRate = 1;
  elements.currentTime.textContent = formatTime(0);
  elements.progressBar.style.setProperty("--progress", `${0}%`);
  resetPlaybackSpeed(elements);
  resetHeadlineAnimation(elements.headline);
}
function resetPlaybackSpeed(elements) {
  if (window.CHANNEL_DESKTOP) {
    elements.customSelectSpeedValues.classList.remove("active");
    elements.customSelectSpeedButton.firstChild.textContent = speedValues[1].label;
  } else {
    elements.selectSpeed.querySelector("select").selectedIndex = 1;
  }
}
function resetHeadlineAnimation(animatedHeadline) {
  const duplicateText = animatedHeadline.querySelector(".duplicate");
  if (duplicateText) animatedHeadline.removeChild(duplicateText);
  animatedHeadline.classList.remove("slide-left-animate", "slide-left-animate-start");
  const headline = animatedHeadline.querySelector("h3");
  if (headline) {
    headline.style.animationDuration = "";
    headline.style.animationDelay = "";
  }
  removeEvent(animatedHeadline, "click", handleMaximize);
}
function updateAudioData(player, audio) {
  const totalTime = player.querySelector(".audio-player__duration__total-time");
  totalTime.textContent = formatTime(audio.duration);
}
function handlePopup(player, popupText) {
  player.classList.toggle("popup");
  popupText.classList.toggle("visible");
}
function setupMinimize(player, elements) {
  const backdrop = elements.backdrop;
  backdrop.classList.add("active");
  removeEvent(backdrop, "touchstart", handleBackdropClick);
  addEvent(backdrop, "touchstart", handleBackdropClick);
  function handleBackdropClick() {
    if (player && !player.classList.contains("speech--hidden")) {
      player.classList.add("minimized");
      backdrop.classList.remove("active");
      addEvent(elements?.headline, "click", () => handleMaximize(player, elements));
      addEvent(elements.maximizeButton, "click", () => handleMaximize(player, elements));
    }
  }
}
function handleMaximize(player, elements) {
  player.classList.remove("minimized");
  elements.backdrop.classList.add("active");
  setupMinimize(player, elements);
}
function animateHeadline(element) {
  const headline = element?.querySelector("h3");
  const headlineWidth = headline?.scrollWidth;
  const animatedHeadlineWidth = element?.clientWidth;
  if (headlineWidth > animatedHeadlineWidth) {
    element.classList.add("slide-left-animate");
    const duplicateText = headline.cloneNode(true);
    duplicateText.classList.add("duplicate");
    element.appendChild(duplicateText);
    const totalWidth = headlineWidth * 2;
    const containerWidth = element.clientWidth;
    const animationDuration = totalWidth / containerWidth * 7;
    headline.style.animationDuration = `${animationDuration}s`;
    duplicateText.style.animationDuration = `${animationDuration}s`;
    element.classList.add("slide-left-animate-start");
  }
}
function handleSelectSpeed(e, audio) {
  const selectedSpeed = parseFloat(e.target.value);
  const newSpeed = speedValues.find(s => s.value === selectedSpeed);
  if (newSpeed) {
    audio.playbackRate = newSpeed.value;
  }
}
function handleCustomSpeed(values, speedButton, audio) {
  const documentClickHandler = e => {
    if (!values.contains(e.target) && !speedButton.contains(e.target)) {
      if (values.classList.contains("active")) {
        values.classList.remove("active");
      }
      removeEvent(document, "click", documentClickHandler);
    }
  };
  values.classList.toggle("active");
  const speedButtons = [...values.querySelectorAll("button")];
  speedButtons.forEach(button => {
    addEvent(button, "click", e => {
      const speedText = e.currentTarget.textContent;
      const speedValue = speedValues.find(speed => speed.label === speedText)?.value;
      speedButton.firstChild.textContent = speedText;
      if (speedValue !== undefined) {
        audio.playbackRate = speedValue;
      }
      values.classList.remove("active");
      removeEvent(document, "click", documentClickHandler);
    });
  });
  if (!eventListeners.has(document)) {
    addEvent(document, "click", documentClickHandler);
  }
}
function getElements(player) {
  const selectors = {
    playButton: ".audio-player__controls__play-btn",
    popup: ".audio-player__info",
    popupText: ".audio-player__info-text",
    maximizeButton: ".audio-player__maximize-btn",
    headline: ".audio-player__animated-headline",
    currentTime: ".audio-player__duration__current-time",
    progressBar: ".audio-player__progress-bar",
    closeButton: ".audio-player__close-btn",
    selectSpeed: ".audio-player__select-speed",
    customSelectSpeedValues: ".audio-player__custom-select-speed-values",
    customSelectSpeedButton: ".audio-player__custom-select-speed-btn",
    audio: "audio",
    backdrop: ".speech__backdrop"
  };
  const elements = {};
  for (const key in selectors) {
    if (key === "backdrop") {
      elements[key] = player.previousElementSibling;
    } else {
      elements[key] = player.querySelector(selectors[key]);
    }
  }
  return elements;
}
function addEvent(element, event, handler, options) {
  if (element) {
    element.addEventListener(event, handler, options);
    eventListeners.set(element, {
      event,
      handler
    });
  }
}
function removeEvent(element, event) {
  const listener = eventListeners.get(element);
  if (listener && listener.event === event) {
    element.removeEventListener(event, listener.handler);
    eventListeners.delete(element);
  }
}
function removeAllEvents() {
  eventListeners.forEach((listener, element) => {
    element.removeEventListener(listener.event, listener.handler);
  });
  eventListeners.clear();
}