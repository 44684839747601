import { setupNativeArticleLock } from "../integration/tabletHybridBridge";
import continuousScroll from "../plugins/continuousScroll";
import { getCurrentScheme } from "../plugins/colorScheme";
import liveReportWidget from "@exp/scripts/plugins/live-report-widget";
import readMoreSlider from "../plugins/readMoreSlider";
import scroll from "@exp/scripts/helpers/scroll";
import slideshow from "@exp/components/slideshow/slideshow.mjs";
import socialMediaEmbeds from "../plugins/socialMediaEmbeds";
import socialPanel from "@exp/components/social-panel/social-panel.mjs";
import stickySlideshow from "../plugins/stickySlideshow";
import brandedContentBanner from "../plugins/brandedContentBanner";
import trackScroll from "../plugins/trackScroll";
import uxLiteForm from "../plugins/uxLiteForm";
import podcast from "@exp/scripts/plugins/podcast";
import trackBrandedLinkClick from "@exp/scripts/helpers/tracking/trackBrandedLinkClick";
import { pushFurtherReadingEvent, pushArticleScroll } from "@exp/scripts/integration/gtm";
import mostReadSlider from "../plugins/mostReadSlider";
import speech from "@exp/components/speech/speech.mjs";
export default function init(componentInitiators) {
  componentInitiators.high.forEach(fn => fn());
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.ExpressenAnalytics = window.ExpressenAnalytics || [];
  const container = document.getElementsByClassName("article")[0];
  article(container);
  continuousScroll(perArticleInit);
  componentInitiators.medium.forEach(fn => fn());
  uxLiteForm();
  function perArticleInit() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    componentInitiators.high.forEach(fn => fn(...args));
    article(...args);
    componentInitiators.medium.forEach(fn => fn(...args));
  }
}
export function article(container) {
  if (!container) return;
  slideshow({
    container: "slideshow",
    bulletContainer: "slider__bullets",
    itemContainer: "slider__items",
    controls: "slider__control"
  }, container);
  if (container.classList.contains("branded-content")) {
    trackBrandedLinkClick(container);
  }
  trackReadMoreLinkClick(container);
  liveReportWidget(container, getCurrentScheme());
  speech(container);
  podcast(container);
  brandedContentBanner(container);
  socialMediaEmbeds(container, {
    containerClassName: "rich-text__block"
  });
  socialPanel(container);
  mostReadSlider(container);
  readMoreSlider(container);
  premiumAreas(container);
  stickySlideshow(container);
  if (window.CHANNEL_MOBILE || window.CHANNEL_TABLET) {
    setupNativeArticleLock(container);
  }
  if (window.Exp && window.Exp.pageTemplateName === "storytelling-article") {
    trackScroll();
  }
  const articleFooter = container.getElementsByClassName("article__footer")[0];
  if (articleFooter) {
    scroll.add(trackArticleBottomScroll);
  }
  function trackArticleBottomScroll() {
    if (articleFooter.getBoundingClientRect().top >= window.innerHeight) return;
    scroll.remove(trackArticleBottomScroll);
    pushArticleScroll();
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("action", "scroll", "Article foot");
    });
  }
}
function trackReadMoreLinkClick(container) {
  const readMoreLinks = container.querySelectorAll(".rich-text__read-more a");
  if (readMoreLinks.length === 0) return;
  [...readMoreLinks].forEach(link => {
    if (link.href) {
      link.addEventListener("click", () => {
        pushFurtherReadingEvent({
          title: link.innerText,
          url: link.href
        }, "read more link");
      });
    }
  });
}
function premiumAreas(container) {
  if (!container) return;
  const antichurnLink = container.getElementsByClassName("article__premium-antichurn")[0];
  if (antichurnLink) {
    antichurnLink.addEventListener("click", () => {
      window.ExpressenAnalytics.push(analytics => {
        analytics.track.customActive("premium", "click", "uncancel offer");
      });
    });
  }
}