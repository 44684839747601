import { handleMileStoneTracking, handlePulseTracking, handleStartTracking } from "./audio-player-tracking.mjs";
export { audioPlayer as default, formatTime };
function audioPlayer(player, playerType) {
  if (player.dataset.isActive === "true") {
    return;
  }
  player.dataset.isActive = "true";
  const milestones = [25, 50, 75, 90, 100];
  let previousVolume = "0.5";
  let isMuted = false;
  let lastMilestone = 0;
  let isDragging = false;
  let currentProgress = 0;
  let activeProgressBar = null;
  let isPlaying = false;
  let gtmData = null;
  let progressBarRect = null;
  const audio = player.querySelector("audio");
  audio.volume = previousVolume;
  const elementsToQuery = ["duration__current-time", "controls__play-btn", "volume-content__btn", "progress-bar", "duration__total-time", "volume-content__slider", ...(playerType === "speech" ? ["skip-forward", "skip-backward", "floating-current-time"] : [])];
  const [currentTime, playButton, volumeButton, progressBar, totalTime, volumeSlider, skipForward, skipBackward, floatingCurrentTime] = elementsToQuery.map(className => player.querySelector(`.audio-player__${className}`));
  if (player.dataset.tracking) {
    gtmData = JSON.parse(player.dataset.tracking);
  }
  if (playerType === "podcast" && window.expNative) {
    playButton.addEventListener("click", () => {
      const searchParams = `${window.location.search === "" ? "?" : `${window.location.search}&`}episodeUrl=${audio.dataset.episodeUrl}`;
      window.expNative.openPodcast(`${window.location.href}${searchParams}`);
    });
  } else {
    playButton.addEventListener("click", handleInitPlay, {
      once: true
    });
    if (playerType === "speech") {
      skipForward?.addEventListener("click", handleSkip);
      skipBackward?.addEventListener("click", handleSkip);
    }
    if (window.CHANNEL_DESKTOP) {
      volumeSlider.value = previousVolume;
      ["mousedown", "mouseup"].forEach(event => {
        volumeSlider.addEventListener(event, toggleThumbSize);
      });
      volumeSlider.addEventListener("input", handleVolumeSlide);
      audio.addEventListener("volumechange", handleVolumeChange);
      volumeButton.addEventListener("click", handleVolumeClick);
    }
  }
  function getMilestone(progress) {
    return milestones.find(m => progress >= m && m > lastMilestone);
  }
  function setProgress(newProgress) {
    progressBar.style.setProperty("--progress", `${newProgress}%`);
  }
  function handleInitPlay() {
    Object.entries({
      loadedmetadata: handleLoadedMetadata,
      play: handlePlayPauseEnd,
      pause: handlePlayPauseEnd,
      ended: handlePlayPauseEnd,
      timeupdate: handleTimeUpdate
    }).forEach(_ref => {
      let [event, handler] = _ref;
      return audio.addEventListener(event, handler);
    });
    progressBar.addEventListener("mousedown", handleStartDrag);
    progressBar.addEventListener("touchstart", handleStartDrag);
    progressBar.addEventListener("click", updateProgress);
    handlePlayClick();
    playButton.addEventListener("click", handlePlayClick);
  }
  function handlePlayClick() {
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  }
  function handlePlayPauseEnd(_ref2) {
    let {
      type
    } = _ref2;
    isPlaying = type === "play";
    if (isPlaying) {
      player.classList.add("playing");
      handlePulseTracking(audio, gtmData, true, playerType);
      handleStartTracking(audio, gtmData, playerType);
      if (!window.CHANNEL_DESKTOP && playerType === "podcast") {
        setMediaMetadata();
      }
    }
    if (type === "pause") {
      handlePulseTracking(audio, gtmData, false, playerType);
      player.classList.remove("playing");
    }
    if (type === "end") {
      currentProgress = 0;
    }
  }
  function setMediaMetadata() {
    if (!("mediaSession" in navigator)) return;
    const artwork = player.querySelector("img").src || "";
    const title = player.querySelector(".audio-player__information__title").textContent.trim() || "Expressen";
    const name = player.querySelector(".audio-player__information__name").textContent.trim() || "Expressen";
    navigator.mediaSession.metadata = new MediaMetadata({
      title,
      artist: name,
      artwork: [{
        src: artwork,
        type: "image/jpeg"
      }]
    });
  }
  function showFloatingCurrentTime() {
    if (floatingCurrentTime) {
      floatingCurrentTime.classList.remove("hidden");
    }
  }
  function hideFloatingCurrentTime() {
    if (floatingCurrentTime) {
      floatingCurrentTime.classList.add("hidden");
    }
  }
  function updateProgress(e) {
    if (!isDragging) return;
    const {
      width,
      x
    } = calculateProgressBarPosition(progressBarRect, e);
    currentProgress = x / width * 100;
    currentProgress = Math.max(0, Math.min(currentProgress, 100));
    setProgress(currentProgress);
    if (!isNaN(audio.duration)) {
      const time = formatTime(currentProgress / 100 * audio.duration);
      currentTime.textContent = time;
      if (isDragging && floatingCurrentTime) {
        floatingCurrentTime.firstElementChild.textContent = time;
      }
    }
  }
  function handleStartDrag(e) {
    const touch = !!e.touches;
    isDragging = true;
    activeProgressBar = e.target;
    progressBar.classList.add("active");
    progressBarRect = progressBar.getBoundingClientRect();
    if (!isNaN(audio.duration) && floatingCurrentTime) {
      showFloatingCurrentTime();
    }
    updateProgress(touch ? e.touches[0] : e);
    document.addEventListener(touch ? "touchmove" : "mousemove", handleMove, {
      passive: false
    });
    document.addEventListener(touch ? "touchend" : "mouseup", handleStopDrag, {
      once: true
    });
  }
  function handleStopDrag(e) {
    if (activeProgressBar === null) return;
    const touch = !!e.touches;
    isDragging = false;
    progressBar.classList.remove("active");
    audio.currentTime = currentProgress / 100 * audio.duration;
    activeProgressBar = null;
    document.removeEventListener(touch ? "touchmove" : "mousemove", handleMove);
    if (floatingCurrentTime) {
      hideFloatingCurrentTime();
    }
  }
  function handleMove(e) {
    if (activeProgressBar === null || !isDragging) return;
    const touch = !!e.touches;
    e.preventDefault();
    updateProgress(touch ? e.touches[0] : e);
  }
  function handleTimeUpdate() {
    if (isDragging || !isPlaying) return;
    const newProgress = getNewProgress();
    currentProgress = newProgress;
    setProgress(newProgress);
    currentTime.textContent = formatTime(audio.currentTime);
    const milestone = getMilestone(newProgress);
    if (!audio.paused || milestone === 100) {
      if (milestone) {
        handleMileStoneTracking(audio, gtmData, milestone, playerType);
        lastMilestone = milestone;
      }
    }
  }
  function handleLoadedMetadata() {
    progressBar.style.pointerEvents = "auto";
    totalTime.textContent = formatTime(audio.duration);
  }
  function handleVolumeChange(e) {
    isMuted = e.target.volume <= 0;
    volumeSlider.value = e.target.volume;
    setVolumeStyle(volumeSlider);
    player.classList.toggle("muted", isMuted);
  }
  function handleVolumeClick() {
    if (!isMuted) {
      previousVolume = audio.volume;
      audio.volume = 0;
      volumeSlider.value = 0;
      isMuted = true;
    } else {
      audio.volume = previousVolume;
      volumeSlider.value = previousVolume;
      isMuted = false;
    }
  }
  function handleVolumeSlide(e) {
    audio.volume = e.target.value;
  }
  function handleSkip() {
    const skipValue = parseInt(this.getAttribute("data-scrub"), 10);
    if (!isNaN(skipValue)) {
      audio.currentTime = Math.max(0, Math.min(audio.currentTime + skipValue, audio.duration));
      currentTime.textContent = formatTime(audio.currentTime);
      if (!isPlaying) {
        const newProgress = getNewProgress();
        setProgress(newProgress);
      }
    }
  }
  function getNewProgress() {
    return audio.currentTime / audio.duration * 100 || 0;
  }
}
function calculateProgressBarPosition(progressBarRect, e) {
  const x = e.clientX - progressBarRect.left;
  const width = progressBarRect.width;
  const clampedX = Math.max(0, Math.min(x, width));
  return {
    clampedX,
    width,
    x
  };
}
function formatTime(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    return "00:00";
  }
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = Math.floor(seconds).toString().padStart(2, "0");
  return hours > 0 ? `${hours}:${formattedMinutes}:${formattedSeconds}` : `${formattedMinutes}:${formattedSeconds}`;
}
function setVolumeStyle(volumeSlider) {
  const value = volumeSlider.value;
  const slider = volumeSlider;
  const percentage = (value - slider.min) / (slider.max - slider.min) * 100;
  slider.style.setProperty("--volume", `${percentage}%`);
}
function toggleThumbSize(e) {
  e.currentTarget.classList.toggle("active");
}