import { checkConsentForVendorSync } from "../integration/didomi";
const requiredVendors = ["c:bn-facebook", "c:bn-instagram", "c:bn-youtube", "c:bn-twitter"];
window.liveReport = window.liveReport || [];
export default function setup(scope) {
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(() => {
    const widgets = (scope || document).getElementsByClassName("live-report-widget");
    if (!widgets.length) return;
    const consent = requiredVendors.every(vendorId => checkConsentForVendorSync(vendorId));
    const adSlotName = window.CHANNEL_MOBILE ? "mob" : "articlemodule";
    window.liveReport.push(api => {
      api.init({
        ssr: true,
        adSlotName,
        apiHost: window.Exp.config.liveReportUrl,
        consent
      });
    });
    for (let i = 0, len = widgets.length; i < len; i++) {
      loadWidget(widgets[i]);
    }
  });
}
function loadWidget(widget) {
  const id = widget.dataset.resourceId;
  if (!id) return;
  window.liveReport.push(api => {
    api.load(widget, id);
  });
}