export default function init(componentInitiators) {
  componentInitiators.high.forEach(fn => fn());
  componentInitiators.medium.forEach(fn => fn());
  const element = document.getElementsByClassName("page")[0];
  initPaginationLinks(element);
  initLoadMore(element);
}
function initPaginationLinks(element) {
  const paginationLinks = element?.getElementsByClassName("list-page__pagination-links")[0];
  if (!paginationLinks) return;
  const previousButton = paginationLinks.getElementsByClassName("list-page__pagination-links__previous")[0];
  const nextButton = paginationLinks.getElementsByClassName("list-page__pagination-links__next")[0];
  const selectedLink = paginationLinks.querySelector("a.selected");
  previousButton.addEventListener("click", () => {
    if (selectedLink?.previousElementSibling?.tagName === "A") {
      selectedLink.previousElementSibling.click();
    }
  });
  nextButton.addEventListener("click", () => {
    if (selectedLink?.nextElementSibling?.tagName === "A") {
      selectedLink.nextElementSibling.click();
    }
  });
}
function initLoadMore(element) {
  const loadMoreButton = element?.getElementsByClassName("list-page__load-more")[0];
  const list = loadMoreButton && element.getElementsByClassName("list-page__items")[0];
  if (!list || !loadMoreButton) return;
  let nextPage = 2;
  const {
    type,
    ...nextOptions
  } = loadMoreButton.dataset;
  const nextParams = new URLSearchParams(nextOptions);
  loadMoreButton.addEventListener("click", loadMore);
  function loadMore() {
    if (type === "todays-news") {
      const date = list.lastElementChild.getElementsByTagName("time")[0].getAttribute("datetime");
      nextParams.set("date", date);
    } else {
      nextParams.set("page", nextPage);
    }
    loadMoreButton.disabled = true;
    const path = `/list-page-next/${type}/?${nextParams.toString()}`;
    fetch(path, {
      credentials: "same-origin"
    }).then(result => {
      if (!result.ok) return;
      if (result.headers.has("last-page")) {
        loadMoreButton.classList.add("hidden");
      }
      return result.text();
    }).then(markup => {
      loadMoreButton.disabled = false;
      if (!markup) return;
      list.insertAdjacentHTML("beforeend", markup);
      nextPage++;
    }).catch(() => {
      loadMoreButton.disabled = false;
    });
  }
}