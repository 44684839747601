import passiveIfPossible from "@exp/scripts/helpers/passive-if-possible";
import scroll from "@exp/scripts/helpers/scroll";
export default function init() {
  const toggleLinkButton = document.getElementById("site-menu-button");
  const container = document.getElementById("site-menu");
  if (!toggleLinkButton || !container) return;
  let open = false;
  let header;
  const [backdrop, innerContainer] = container.children;
  const contentContainer = innerContainer.firstElementChild;
  toggleLinkButton.addEventListener("click", toggleMenu);
  backdrop.addEventListener("click", toggleMenu);
  contentContainer.addEventListener("touchstart", keepScrollWithinMenu, passiveIfPossible);
  contentContainer.addEventListener("click", trackMenuNavigation, passiveIfPossible);
  contentContainer.addEventListener("transitionend", focusIfOpen);
  contentContainer.addEventListener("focusout", focusToggleLink);
  setupSubMenus();
  const bodyMutationObserver = new MutationObserver(dodgeStickyHeader);
  bodyMutationObserver.observe(document.body, {
    attributes: true,
    attributeFilter: ["class"]
  });
  function toggleMenu(event) {
    event.preventDefault();
    if (!open) {
      dodgeStickyHeader();
    }
    open = container.classList.toggle("site-menu--open");
    toggleLinkButton.classList.toggle("top-menu__site-menu-button--open");
    scroll.toggleLock(open);
  }
  function focusIfOpen(event) {
    if (event.propertyName === "visibility" && open) {
      contentContainer.focus();
    }
  }
  function focusToggleLink(event) {
    if (!contentContainer.contains(event.relatedTarget)) {
      toggleLinkButton.focus();
    }
  }
  function dodgeStickyHeader(mutations) {
    if (mutations) {
      if (!mutations[0].target.classList.contains("wallpaper-ad-visible")) return;
      bodyMutationObserver.disconnect();
    }
    const stickyWrapper = document.getElementsByClassName("site-header__sticky-wrapper")[0];
    header = header || stickyWrapper || document.getElementsByClassName("site-header__row-2")[0];
    const {
      bottom,
      left
    } = header.getBoundingClientRect();
    innerContainer.style.top = `${bottom}px`;
    if (stickyWrapper) innerContainer.style.left = `${left}px`;
  }
  function setupSubMenus() {
    const subMenuButtons = container.getElementsByTagName("button");
    for (let i = 0; i < subMenuButtons.length; i++) {
      const subMenuButton = subMenuButtons[i];
      if (!subMenuButton.className) {
        subMenuButton.addEventListener("click", toggleSubMenu);
      }
    }
  }
  function toggleSubMenu() {
    this.parentElement.classList.toggle("-open");
  }
  function keepScrollWithinMenu() {
    const {
      scrollTop
    } = contentContainer;
    let notStartOrEnd = Math.max(scrollTop, 1);
    if (scrollTop > 0) {
      const {
        clientHeight,
        scrollHeight
      } = contentContainer;
      notStartOrEnd = Math.min(scrollTop, scrollHeight - clientHeight - 1);
    }
    contentContainer.scrollTop = notStartOrEnd;
  }
  function trackMenuNavigation(event) {
    const link = event.target.closest("a[href]");
    if (!link) return;
    window.dataLayer = window.dataLayer || [];
    window.ExpressenAnalytics = window.ExpressenAnalytics || [];
    const linkPath = getLinkPath(link).join("|").toLowerCase();
    if (!linkPath.length) return;
    window.dataLayer.push({
      event: "navigation link click",
      eventParams: {
        category: "navigation"
      },
      engagement: {
        type: "link click"
      },
      component: {
        position: "top left of page",
        name: linkPath,
        type: "hamburger menu"
      },
      destination: {
        url: link.href
      }
    });
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("navigation", "click", `main_menu|${linkPath}`);
    });
  }
  function getLinkPath(element) {
    let currentPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (!element || !["A", "BUTTON"].includes(element.tagName)) {
      return currentPath;
    }
    currentPath.unshift(element.textContent);
    const parentListListItem = element.parentElement.parentElement.parentElement;
    if (parentListListItem.tagName !== "LI") return currentPath;
    return getLinkPath(parentListListItem.firstElementChild, currentPath);
  }
}