import livetvWidget from "../plugins/livetvWidget";
import * as ecommerce from "../integration/ecommerceTracking";
import uxLiteForm from "../plugins/uxLiteForm";
import { pushBlockEvent } from "@exp/scripts/integration/gtm";
const useObserver = ("IntersectionObserver" in window);
export default function init(componentInitiators) {
  premiumWidgetTracker();
  componentInitiators.high.forEach(fn => fn());
  livetvWidget();
  componentInitiators.medium.forEach(fn => fn());
  uxLiteForm();
  blockClickTracker();
  blockImpressionTracker();
}
function premiumWidgetTracker() {
  const mainColumn = document.getElementsByClassName("site-body__column-2")[0];
  if (!mainColumn) return;
  const user = window.Exp && window.Exp.user;
  if (user && user.entitlements && user.entitlements.length) return;
  const mainColumnPremiumLinks = mainColumn.getElementsByClassName("premium-link");
  trackLinkClick(mainColumnPremiumLinks);
  if (window.CHANNEL_TABLET || window.CHANNEL_DESKTOP || window.CHANNEL_RESPONSIVE) {
    const complementaryColumn = document.getElementsByClassName("site-body__column-3")[0];
    const complementaryPremiumLinks = complementaryColumn && complementaryColumn.getElementsByClassName("premium-link");
    trackLinkClick(complementaryPremiumLinks);
  }
  function trackLinkClick(links) {
    if (!links || !links.length) return;
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener("click", ecommerce.productClick);
    }
  }
}
function blockClickTracker() {
  const autoteasers = window.document.querySelectorAll(".teaser[data-autoteaser=true]");
  if (!autoteasers.length) return;
  for (const autoteaser of autoteasers) {
    autoteaser.addEventListener("click", e => {
      const aTag = e.target.closest("a");
      if (!aTag) return;
      const trackingInfo = JSON.parse(aTag.dataset.trackingInfo);
      pushBlockEvent("click", trackingInfo);
    });
  }
}
function blockImpressionTracker() {
  if (!useObserver) return;
  const autoteasers = window.document.querySelectorAll(".teaser[data-autoteaser=true]");
  if (!autoteasers) return;
  const teaserObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        teaserObserver.unobserve(entry.target);
        const link = entry.target.querySelector("a");
        if (!link) return;
        const trackingInfoJson = link.dataset.trackingInfo;
        if (!trackingInfoJson) return;
        const trackingInfo = JSON.parse(trackingInfoJson);
        pushBlockEvent("impression", trackingInfo);
      }
    });
  });
  for (const autoteaser of autoteasers) {
    teaserObserver.observe(autoteaser);
  }
}